import React from 'react';
import Layout from '../layouts/en';
import timboAfrica from '../assets/images/timbo-africa_2.jpg';
import longTermGoals from '../assets/images/long-term-goals_2.jpg';
import iconMarketingSalesStrategy from '../assets/images/icons/icon-marketing-sales-strategy.svg';
import iconProfability from '../assets/images/icons/icon-profability.svg';
import iconIncreaseParcel from '../assets/images/icons/icon-increase-parcel.svg';
import iconFinancialSupport from '../assets/images/icons/icon-financial-support.svg';
import timboLogoWhite from '../assets/images/timbo-logo-white.png';
import Scroll from './../components/Scroll';

export const MARKETING_SALES = 1;
export const PROFITABILITY = 2;
export const INCREASE_PARCEL = 3;
export const FINANCIAL_SUPPORT = 4;

class corporateResponsibilityPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mobileStrategies: {
                marketingSales: false,
                profability: false,
                increaseParcel: false,
                financialSupport: false,
            }        
        }
    }

    handleExpandingFullStrategy(section) {
        switch (section) {
            case MARKETING_SALES:
                this.setState({ mobileStrategies: {
                    marketingSales: true,
                    profability: this.state.mobileStrategies.profability,
                    increaseParcel: this.state.mobileStrategies.increaseParcel,
                    financialSupport: this.state.mobileStrategies.financialSupport
                }});
                // this.mobileStrategies.marketingSales = true;
                break;
            case PROFITABILITY:
                this.setState({ mobileStrategies: {
                    profability: true,
                    marketingSales: this.state.mobileStrategies.marketingSales,
                    increaseParcel: this.state.mobileStrategies.increaseParcel,
                    financialSupport: this.state.mobileStrategies.financialSupport
                }});
                // this.mobileStrategies.profability = true;
                break;
            case INCREASE_PARCEL:
                this.setState({ mobileStrategies: {
                    increaseParcel: true,
                    profability: this.state.mobileStrategies.profability,
                    marketingSales: this.state.mobileStrategies.marketingSales,
                    financialSupport: this.state.mobileStrategies.financialSupport
                }});
                // this.mobileStrategies.increaseParcel = true;
                break;
            case FINANCIAL_SUPPORT:
                this.setState({ mobileStrategies: {
                    financialSupport: true,
                    profability: this.state.mobileStrategies.profability,
                    increaseParcel: this.state.mobileStrategies.increaseParcel,
                    marketingSales: this.state.mobileStrategies.marketingSales
                }});
                // this.mobileStrategies.financialSupport = true;
                break;
            default:
                break;
        }
    }

    handleCollapsingStrategy(section) {
        switch (section) {
            case MARKETING_SALES:
                this.setState({ mobileStrategies: {
                    marketingSales: false,
                    profability: this.state.mobileStrategies.profability,
                    increaseParcel: this.state.mobileStrategies.increaseParcel,
                    financialSupport: this.state.mobileStrategies.financialSupport
                }});
                // this.mobileStrategies.marketingSales = true;
                break;
            case PROFITABILITY:
                this.setState({ mobileStrategies: {
                    profability: false,
                    marketingSales: this.state.mobileStrategies.marketingSales,
                    increaseParcel: this.state.mobileStrategies.increaseParcel,
                    financialSupport: this.state.mobileStrategies.financialSupport
                }});
                // this.mobileStrategies.profability = true;
                break;
            case INCREASE_PARCEL:
                this.setState({ mobileStrategies: {
                    increaseParcel: false,
                    profability: this.state.mobileStrategies.profability,
                    marketingSales: this.state.mobileStrategies.marketingSales,
                    financialSupport: this.state.mobileStrategies.financialSupport
                }});
                // this.mobileStrategies.increaseParcel = true;
                break;
            case FINANCIAL_SUPPORT:
                this.setState({ mobileStrategies: {
                    financialSupport: false,
                    profability: this.state.mobileStrategies.profability,
                    increaseParcel: this.state.mobileStrategies.increaseParcel,
                    marketingSales: this.state.mobileStrategies.marketingSales
                }});
                // this.mobileStrategies.financialSupport = true;
                break;
            default:
                break;
        }
    }

    render() {
                     
        return (
            <Layout>
                <header id="header" className="masthead corporate-responsibility-photo">
                    <div className="container d-flex h-100 align-items-center page-wrapper">
                        <div className="center-block text-center col-sm-12">
                            <div className="timbo-logo-wrapper">
                                <img src={timboLogoWhite} alt="Timbo Africa Foundation" />
                            </div>
                            <h1 className="mx-auto my-0 homepage-main-text corporate-responsibility-main-text">Our approach in practice</h1>
                        </div>
                    </div>
                </header>

                <section className="container content-wrapper corporate-responsibility-wrapper">
                    <div className="corporate-responsibility-container first-corporate-responsibility-container">
                        <div className="corporate-responsibility-section">
                            <h2 className="corporate-responsibility-title">Timbo Africa Foundation</h2>
                            <div className="corporate-responsibility-text">
                                <p>
                                    Timbo Foundation is committed to protect the wildlife in Africa by creating corridors between the various existing
                                    natural areas so that the migration of wildlife becomes possible. In addition, she tries to involve the local youth through research,
                                    training and education in order to give them the opportunity for a career in nature conservation.
                                </p>
                                <br/>
                                <p>
                                    The Timbo Foundation was founded by ACC co-founder Albert Hartog in 2009
                                </p>
                                <br/>
                                <p>
                                    The Dream (Timbo 2050): to create one of the world’s largest wildlife corridors connecting Botswana to Mozambique for future generations
                                </p>
                                <br/>
                                <p>
                                    Koro River Camp was established in the reserve of the Timbo foundation to create recurring source of income to fund (i.e.)
                                    anti-poaching and nature preservation  
                                </p>
                            </div>
                        </div>
                        <div className="corporate-responsibility-section">
                            <div className="corporate-responsibility-first-image">
                                <img src={timboAfrica} alt="Timbo Africa Foundation"/>        
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="clearfix"></div>

                    <div className="corporate-responsibility-container second-responsibility-container">
                        <div className="corporate-responsibility-section corporate-responsibility-custom-section">
                            <div className="corporate-responsibility-text">
                                <p>
                                    The Foundation operates primarily in Botswana and South Africa but also raises funds directly for support of cancer research in The Netherlands.
                                </p>
                            </div>
                            <h2 className="corporate-responsibility-title">Long term goals</h2>
                            <div className="corporate-responsibility-text">
                                <p>
                                    Be a leading party in the realisation of corridors to Kruger and Mozambique
                                </p>
                                <br/>
                                <p>
                                    Train local people to find a job in nature conservation and hospitality
                                </p>
                                <br/>
                                <p>
                                    Reintroduce endangered species
                                </p>
                                <br/>
                                <p>
                                    Support research of land and species (e.g. game count, carrying capacity)
                                </p>
                            </div>
                        </div>
                        <div className="corporate-responsibility-section">
                            <div className="corporate-responsibility-second-image">
                                <img src={longTermGoals} alt="Long term goals"/>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>

                    <div className="corporate-responsibility-container third-corporate-responsibility-container">
                        <div className="corporate-responsibility-information-container corporate-responsibility-information-container-mobile-hide">
                            <div className="corporate-responsibility-information-first">
                                &nbsp;    
                            </div>
                            <div className="corporate-responsibility-information-first corporate-responsibility-information-custom-title">
                                <div className="corporate-responsibility-information-title">
                                    Goals
                                </div>
                            </div>
                            <div className="corporate-responsibility-information-first corporate-responsibility-information-custom-title">
                                <div className="corporate-responsibility-information-title">
                                    ACC’s contribution
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>

                        <div className="corporate-responsibility-information-container">
                            <div className="corporate-responsibility-information-first">
                                <img src={iconMarketingSalesStrategy} alt="Marketing and sales strategy" />
                                <div className="corporate-responsibility-icon-title">
                                    Marketing &amp; sales <br />
                                    strategy
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix desktop-hidden"></div>
                            <div onClick={() => this.handleExpandingFullStrategy(MARKETING_SALES)} className={ this.state.mobileStrategies.marketingSales ? 'full-strategy-button hide-full-button' : 'full-strategy-button' }>
                                <span>See full strategy</span>
                            </div>
                            <div className={ this.state.mobileStrategies.marketingSales ? 'corporate-responsibility-info-wrapper display-mobile' : 'corporate-responsibility-info-wrapper' }>
                                <div className="corporate-responsibility-information-second">
                                    <h4 className="corporate-responsibility-information-mobile-title">Goals</h4>
                                    Establish a solid source of income from the lodge to pay for long term nature conservation
                                </div>
                                <div className="corporate-responsibility-information-third">
                                    <h4 className="corporate-responsibility-information-mobile-title">ACC’s contribution</h4>
                                    Set-up marketing & sales strategy for Koro River Camp to maximize occupancy rate in the camp
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div onClick={() => this.handleCollapsingStrategy(MARKETING_SALES)} className={ this.state.mobileStrategies.marketingSales ? 'show-less-button' : 'show-less-button hide-full-button' }>
                                <span>Show less</span>
                            </div>
                        </div>

                        <div className="corporate-responsibility-information-container">
                            <div className="corporate-responsibility-information-first">
                                <img src={iconProfability} alt="Profability" />
                                <div className="corporate-responsibility-icon-title">Profability</div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix desktop-hidden"></div>
                            <div onClick={() => this.handleExpandingFullStrategy(PROFITABILITY)} className={ this.state.mobileStrategies.profability ? 'full-strategy-button hide-full-button' : 'full-strategy-button' }>
                                <span>See full strategy</span>
                            </div>
                            <div className={ this.state.mobileStrategies.profability ? 'corporate-responsibility-info-wrapper display-mobile' : 'corporate-responsibility-info-wrapper' }>
                                <div className="corporate-responsibility-information-second">
                                    <h4 className="corporate-responsibility-information-mobile-title">Goals</h4>
                                    Establish a solid source of income from the lodge to pay for long term nature conservation
                                </div>
                                <div className="corporate-responsibility-information-third">
                                    <h4 className="corporate-responsibility-information-mobile-title">ACC’s contribution</h4>
                                    Structure the Koro River Camp in similar way as portfolio companies for long-term growth and healthy profits
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div onClick={() => this.handleCollapsingStrategy(PROFITABILITY)} className={ this.state.mobileStrategies.profability ? 'show-less-button' : 'show-less-button hide-full-button' }>
                                <span>Show less</span>
                            </div>
                        </div>

                        <div className="corporate-responsibility-information-container">
                            <div className="corporate-responsibility-information-first">
                                <img src={iconIncreaseParcel} alt="Increase parcel" />
                                <div className="corporate-responsibility-icon-title">Increase parcel</div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix desktop-hidden"></div>
                            <div onClick={() => this.handleExpandingFullStrategy(INCREASE_PARCEL)} className={ this.state.mobileStrategies.increaseParcel ? 'full-strategy-button hide-full-button' : 'full-strategy-button' }>
                                <span>See full strategy</span>
                            </div>
                            <div className={ this.state.mobileStrategies.increaseParcel ? 'corporate-responsibility-info-wrapper display-mobile' : 'corporate-responsibility-info-wrapper' }>
                                <div className="corporate-responsibility-information-second">
                                    <h4 className="corporate-responsibility-information-mobile-title">Goals</h4>
                                    Connect Timbo’s River reserve with the Northern Tuli game reserve
                                </div>
                                <div className="corporate-responsibility-information-third">
                                    <h4 className="corporate-responsibility-information-mobile-title">ACC’s contribution</h4>
                                    Guide transactions for new lots to extend corridor
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div onClick={() => this.handleCollapsingStrategy(INCREASE_PARCEL)} className={ this.state.mobileStrategies.increaseParcel ? 'show-less-button' : 'show-less-button hide-full-button' }>
                                <span>Show less</span>
                            </div>
                        </div>

                        <div className="corporate-responsibility-information-container last-corporate-responsibility-information-container">
                            <div className="corporate-responsibility-information-first">
                                <img src={iconFinancialSupport} alt="Financial support" />
                                <div className="corporate-responsibility-icon-title">Financial support</div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix desktop-hidden"></div>
                            <div onClick={() => this.handleExpandingFullStrategy(FINANCIAL_SUPPORT)} className={ this.state.mobileStrategies.financialSupport ? 'full-strategy-button hide-full-button' : 'full-strategy-button' }>
                                <span>See full strategy</span>
                            </div>
                            <div className={ this.state.mobileStrategies.financialSupport ? 'corporate-responsibility-info-wrapper display-mobile' : 'corporate-responsibility-info-wrapper' }>
                                <div className="corporate-responsibility-information-second">
                                    <h4 className="corporate-responsibility-information-mobile-title">Goals</h4>
                                    Support in Timbo’s long-term investments and expansion capital expenditures
                                </div>
                                <div className="corporate-responsibility-information-third">
                                    <h4 className="corporate-responsibility-information-mobile-title">ACC’s contribution</h4>
                                    A percentage of ACC’s carried interest is donated to Timbo
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div onClick={() => this.handleCollapsingStrategy(FINANCIAL_SUPPORT)} className={ this.state.mobileStrategies.financialSupport ? 'show-less-button' : 'show-less-button hide-full-button' }>
                                <span>Show less</span>
                            </div>
                        </div>

                    </div>
                    <div className="clearfix"></div>
                    
                </section>
                <Scroll type="id" element="header">
                    <div className="csr-back back-to-top-wrapper">
                        <a href="?scroll-to=header">
                            <i className="fas fa-angle-double-up"></i>
                            <br />
                            <span className="back-to-top">Back to top</span>
                        </a>
                    </div>
                </Scroll>
            </Layout>
        )    
    }
}

export default corporateResponsibilityPage;